import * as React from "react";
import {
  Box,
  Flex,
  Image,
  Button,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { NEOPIN_DAPP_LINK } from "../config/constants/common";
import ThemeToggle from "./ThemeToggle";
import TopStripBanner from "./TopStripBanner";

const Header: React.FC = () => {
  // Breakpoint에 따라 다른 이미지 경로를 반환
  const logoSrc = useBreakpointValue({
    base: useColorModeValue("/logo-sm.svg", "/logo-sm.svg"),
    md: useColorModeValue("/logo-black.png", "/logo.png"),
  });
  return (
    <Box>
      <TopStripBanner />
      <Box
        as="header"
        w="100%"
        p="12px 40px"
        bg="transparent"
        boxSizing="border-box"
      >
        <Flex align="center" justify="space-between">
          <Link to={"/"}>
            <Image src={logoSrc} alt="Logo" h={{ base: "auto", md: "50px" }} />
          </Link>
          <Flex align={"center"} gap={{ base: "12px", md: "24px" }}>
            <ThemeToggle />
            <Button
              as={"a"}
              href={`${NEOPIN_DAPP_LINK}`}
              target="_blank"
              rel="noreferrer noopener"
              variant="neopin"
              size="md"
              textStyle={{ base: "button14SB", md: "button15SB" }}
            >
              Launch App
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
