import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Text } from "@chakra-ui/react";

const localStorageId = "topStripBanner";
const LEARN_MORE_LINK =
  "https://medium.com/neopin-official/neopin-4-0-announcement-migrating-to-the-fastest-growing-blockchain-ecosystem-in-the-world-solana-8bab7426acba";

export default function TopStripBanner() {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState<boolean>(false);

  const onClickClose = () => {
    setIsShow(false);
    localStorage.setItem(localStorageId, "true");
  };

  useEffect(() => {
    localStorage.getItem(localStorageId) === "true"
      ? setIsShow(false)
      : setIsShow(true);
  }, []);

  return (
    isShow && (
      <Box
        display={"flex"}
        alignItems={"stretch"}
        justifyContent={"space-between"}
        boxSizing={"border-box"}
        width={"100%"}
        backgroundColor={"#7a6eff"}
        color={"white"}
      >
        <Box
          __css={{
            "@media screen and (max-width: 768px)": { display: "none" },
          }}
        />
        <Text
          padding={{ base: "10px", md: "14px" }}
          display={"block"}
          fontSize={{ base: "14px", md: "18px" }}
          fontWeight={400}
          lineHeight={{ base: "20px", md: "32px" }}
          letterSpacing={"-1%"}
        >
          <span>
            {t(
              "With NIP-8 approved, Get Ready to Experience the Next Game-Changing Project Titan on Solana!"
            )}
          </span>
          <span> </span>
          <a
            href={LEARN_MORE_LINK}
            target="_blank"
            rel="noreferrer noopener"
            // onClick={(e) => {
            //   if (isApp()) {
            //     e.preventDefault();
            //     openLinkExternal(LEARN_MORE_LINK);
            //   }
            // }}
          >
            <span
              style={{
                color: "white",
                textDecoration: "underline",
              }}
            >
              {t("Learn More")}
            </span>
          </a>
        </Text>

        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClickClose();
          }}
          variant={"plain"}
          padding={{ base: "0 10px", md: "0 20px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path d="M1.5 10.5L10.5 1.5" stroke="#fff" stroke-width="1.8" />
            <path d="M1.5 1.5L10.5 10.5" stroke="#fff" stroke-width="1.8" />
          </svg>
        </Button>
      </Box>
    )
  );
}
